<template>
  <el-dialog visible class="change-discount-dialog" width="25rem" append-to-body @close="$emit('on-close-modal')">
    <template #title>
      <h2>{{ title }}</h2>
    </template>
    <div class="discount-input-form">
      <el-form ref="form" :model="changeDiscountForm" label-position="top" :rules="rules">
        <el-form-item prop="discountPercentage" :label="$t('eventMapModal.orderCard.costBox.discountLabel')">
          <el-input v-model="changeDiscountForm.discountPercentage" type="number" :min="0" :max="100">
            <span slot="prefix">%</span>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <Button type="secondary" @click="$emit('on-close-modal')">{{
        $t('eventMapModal.orderCard.costBox.cancel')
      }}</Button>
      <Button type="primary" @click="onSubmit">{{ $t('eventMapModal.orderCard.costBox.save') }}</Button>
    </template>
  </el-dialog>
</template>
<script>
import { computed, reactive, getCurrentInstance } from 'vue';
import { Button } from '@/modules/core';
export default {
  name: 'ChangeDiscountRateModal',
  components: { Button },
  props: {
    isAddDiscount: { type: Boolean, required: true },
    currentDiscountPercentage: { type: Number, default: null },
  },
  emits: ['on-close-modal', 'on-change-discount-rate'],
  setup(props) {
    const root = getCurrentInstance().proxy;
    const changeDiscountForm = reactive({
      discountPercentage: props.currentDiscountPercentage ? props.currentDiscountPercentage * 100 : null,
    });
    const validatePercentage = (_, percentageToCheck, callback) => {
      if (percentageToCheck >= 0 && percentageToCheck <= 100) {
        callback();
      } else {
        callback(new Error());
      }
    };

    const rules = {
      discountPercentage: [
        {
          required: true,
          message: root.$t('eventMapModal.orderCard.costBox.invalidPercentage'),
          trigger: 'change',
        },
        {
          validator: validatePercentage,
          message: root.$t('eventMapModal.orderCard.costBox.invalidPercentage'),
          trigger: 'change',
        },
      ],
    };

    const title = computed(() =>
      props.isAddDiscount
        ? root.$t('eventMapModal.orderCard.costBox.addDiscount')
        : root.$t('eventMapModal.orderCard.costBox.updateDiscount')
    );

    return {
      title,
      rules,
      changeDiscountForm,
    };
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const discountRate = this.changeDiscountForm.discountPercentage / 100;
          this.$emit('on-change-discount-rate', { discountRate });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.change-discount-dialog {
  ::v-deep {
    .el-dialog {
      border-radius: 0.5rem;
    }

    .el-input__prefix {
      right: 10px;
    }

    .el-input__inner {
      direction: inherit !important;
      padding-left: 1px !important;
    }

    .el-input {
      width: 7rem;
      height: 2.5rem;
    }
  }
}

.discount-input-form {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-start;

  .discount-input-label {
    font-weight: 500;
  }
}

.change-dialog-title {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
</style>
