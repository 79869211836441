<template>
  <el-dialog visible class="delete-discount-dialog" width="25rem" append-to-body @close="$emit('on-close-modal')">
    <template #title>
      <div class="delete-dialog-title">
        <DeleteWarningIcon />
        <h2>{{ $t('eventMapModal.orderCard.costBox.deleteDiscount') }}</h2>
      </div>
    </template>
    <div>
      {{ $t('eventMapModal.orderCard.costBox.deleteDescription') }}
    </div>
    <template #footer>
      <Button type="secondary" @click="$emit('on-close-modal')">{{
        $t('eventMapModal.orderCard.costBox.cancel')
      }}</Button>
      <Button type="danger" @click="$emit('on-delete-discount-rate')">{{
        $t('eventMapModal.orderCard.costBox.deleteDiscount')
      }}</Button>
    </template>
  </el-dialog>
</template>
<script>
import { DeleteWarningIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
export default {
  name: 'DeleteDiscountRateModal',
  components: { DeleteWarningIcon, Button },
  emits: ['on-close-modal', 'on-delete-discount-rate'],
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.delete-discount-dialog {
  ::v-deep {
    .el-dialog {
      border-radius: 0.5rem;
    }
  }
}

.delete-dialog-title {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
</style>
