<template>
  <div>
    <Tabs :tabs="tabs" :active-tab.sync="activeTab" class="mb-4">
      <template #actions>
        <Button v-if="itemsEditable" type="secondary" class="mb-1 p-1" @click="openCreateForm = true">
          <div class="d-flex align-items-center">
            <PlusIcon />
            {{
              activeTab === 0
                ? $t('eventMapModal.orderCard.productTable.tabs.actions.createOrderedItem')
                : $t('eventMapModal.orderCard.productTable.tabs.actions.createReturnNoticeItem')
            }}
            <div class="mx-1" />
          </div>
        </Button>
      </template>
    </Tabs>
    <Table
      :data="tableData"
      :columns="columns"
      class="order-items-table"
      show-index
      border
      rounded
      expandable
      :hover="false"
    >
      <template #cell-sku="{ rowData: { product } }">
        {{ (product && product.sku) || '-' }}
      </template>
      <template #cell-productName="{ rowData: { product, associatedItems } }">
        <div class="d-flex align-items-center">
          <template v-if="!isNil(associatedItems) && associatedItems.length">
            <HasAssociatedProductsIcon width="16px" height="16px" :class="$direction === 'ltr' ? 'me-2' : 'ms-2'" />
          </template>
          <template v-if="product && product.references.length > 1">
            <AdditionalNamesPopOver
              class="d-inline-block position-absolute"
              :style="$direction === 'ltr' ? 'left: 0px' : 'right: 0px'"
              :icon-size="16"
              :references="product.references"
            />
          </template>
          <p>{{ (product && product.name) || $t('commons.generalProduct') }}</p>
        </div>
      </template>
      <template #cell-quantity="{ rowData: orderItem }">
        <div
          :class="{
            warning: hasUnsolvedDifference(orderItem, 'quantity'),
            solved: hasSolvedDifference(orderItem, 'quantity'),
          }"
        >
          {{ orderItem.quantity }}
        </div>
      </template>
      <template #cell-price="{ rowData: orderItem }">
        <div :class="{ warning: hasDifference(orderItem, 'price') }">
          {{ !isNil(orderItem.price) ? formatMoney(orderItem.price) : $t('commons.unknown') }}
        </div>
      </template>
      <template #cell-discount="{ rowData: orderItem }">
        <div :class="{ warning: hasDifference(orderItem, 'discount') }">
          {{ formatPercent(orderItem.discount) }}
        </div>
      </template>
      <template #cell-associatedItemsTotalAmount="{ rowData: { associatedItemsTotalAmount } }">
        <template v-if="associatedItemsTotalAmount">
          {{ formatMoney(associatedItemsTotalAmount) }}
        </template>
        <template v-else>-</template>
      </template>
      <template #cell-totalAmount="{ rowData: { totalAmount } }">
        {{ !isNil(totalAmount) ? formatMoney(totalAmount) : $t('commons.unknown') }}
      </template>
      <template #cell-firstAction="{ rowData: orderItem }">
        <Button
          v-if="hasDifferences(orderItem)"
          type="warning"
          class="difference-button"
          @click="onSolveDiff(orderItem.id ? orderItem.id : orderItem.differenceItemId)"
        >
          {{ $t('commons.resolveDifference') }}
        </Button>
      </template>
      <template #cell-secondAction="{ rowData: orderItem, rowIndex }">
        <div v-if="itemsEditable" class="event-modal-card-table__kebab-icon">
          <el-dropdown
            v-if="orderItem.product"
            trigger="click"
            placement="bottom"
            @command="(action) => handleAction(action, orderItem)"
            @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
          >
            <i class="co-kebab" :class="{ active: activeActions === rowIndex }" />
            <el-dropdown-menu>
              <el-dropdown-item :disabled="!orderItem.id" :command="UPDATE_TYPE.QUANTITY">
                {{ $t('eventMapModal.updateProductModule.quantity') }}
              </el-dropdown-item>
              <el-dropdown-item :disabled="!orderItem.id" :command="UPDATE_TYPE.PRICE">
                {{ $t('eventMapModal.updateProductModule.price') }}
              </el-dropdown-item>
              <el-dropdown-item :disabled="!orderItem.id" :command="UPDATE_TYPE.DISCOUNT">
                {{ $t('eventMapModal.updateProductModule.discount') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
      <template #expandable-content="{ rowData: { associatedItems, rowIndex } }">
        <table class="table w-100 sub-table fixed-layout">
          <tbody>
            <tr v-for="associatedItem in associatedItems" :key="associatedItem.id">
              <td class="bg-secondary" style="width: 110px"></td>
              <td class="bg-secondary" :style="{ width: columns[0].width }">
                {{ (associatedItem.product && associatedItem.product.sku) || '-' }}
              </td>
              <td class="bg-secondary pe-5 position-relative" :style="{ width: columns[1].width }">
                <div class="d-flex align-items-center">
                  <AssociatedProductIcon
                    v-if="!isNil(associatedItems)"
                    width="16px"
                    height="16px"
                    :class="$direction === 'ltr' ? 'me-2' : 'ms-2'"
                  />
                  <AdditionalNamesPopOver
                    v-if="
                      associatedItem.product &&
                      !isNil(associatedItem.product.references) &&
                      associatedItem.product.references.length > 1
                    "
                    class="d-inline-block position-absolute"
                    :style="$direction === 'ltr' ? 'left: 0px' : 'right: 0px'"
                    :icon-size="16"
                    :references="associatedItem.product.references"
                  />
                  {{ (associatedItem.product && associatedItem.product.name) || $t('commons.generalProduct') }}
                </div>
              </td>
              <td class="bg-secondary" :style="{ width: columns[2].width }">
                <div
                  :class="{
                    warning: hasUnsolvedDifference(associatedItem, 'quantity'),
                    solved: hasSolvedDifference(associatedItem, 'quantity'),
                  }"
                >
                  {{ formatDecimalPoint(associatedItem.quantity) }}
                </div>
              </td>
              <td class="bg-secondary" :style="{ width: columns[3].width }">
                <div :class="{ warning: hasDifference(associatedItem, 'price') }">
                  {{ !isNil(associatedItem.price) ? formatMoney(associatedItem.price) : $t('commons.unknown') }}
                </div>
              </td>
              <td class="bg-secondary" :style="{ width: columns[4].width }">
                <div
                  :class="{
                    warning: hasDifference(associatedItem, 'discount'),
                  }"
                >
                  {{ formatPercent(associatedItem.discount) }}
                </div>
              </td>
              <td class="bg-secondary" :style="{ width: columns[5].width }">-</td>
              <td class="bg-secondary" :style="{ width: columns[6].width }">
                {{
                  !isNil(associatedItem.totalAmount) ? formatMoney(associatedItem.totalAmount) : $t('commons.unknown')
                }}
              </td>
              <td class="bg-secondary" :style="{ width: columns[7].width }">
                <Button
                  v-if="itemsEditable && hasDifferences(associatedItem)"
                  type="warning"
                  class="difference-button"
                  @click="onSolveDiff(associatedItem.id ? associatedItem.id : associatedItem.differenceItemId)"
                >
                  {{ $t('commons.resolveDifference') }}
                </Button>
              </td>
              <td class="bg-secondary" :style="{ width: columns[8].width }">
                <div v-if="itemsEditable" class="event-modal-card-table__kebab-icon">
                  <el-dropdown
                    v-if="associatedItem.product"
                    trigger="click"
                    placement="bottom"
                    @command="(action) => handleAction(action, associatedItem)"
                    @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
                  >
                    <i class="co-kebab" :class="{ active: activeActions === rowIndex }" />
                    <el-dropdown-menu>
                      <el-dropdown-item :command="UPDATE_TYPE.QUANTITY">
                        {{ $t('eventMapModal.updateProductModule.quantity') }}
                      </el-dropdown-item>
                      <el-dropdown-item :command="UPDATE_TYPE.PRICE">
                        {{ $t('eventMapModal.updateProductModule.price') }}
                      </el-dropdown-item>
                      <el-dropdown-item :command="UPDATE_TYPE.DISCOUNT">
                        {{ $t('eventMapModal.updateProductModule.discount') }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Table>
    <OrderItemCreateForm
      v-if="openCreateForm"
      :products="currentSupplierProducts"
      :order-id="orderId"
      :active-tab="activeTab"
      @close="openCreateForm = false"
      @submit="handleItemCreate"
    />
  </div>
</template>

<script>
import { pick, pipe, isNil, not, filter, keys } from 'ramda';
import { computed, ref, getCurrentInstance } from 'vue';

import { useCurrency } from '@/locale/useCurrency';

import { formatCurrencyOrDash, formatPercentOrDash } from '@/modules/purchase-management/purchaseManagementFormatters';

import { Tabs, Button, Table } from '@/modules/core';
import { AdditionalNamesPopOver } from '@/modules/products';
import { HasAssociatedProductsIcon, AssociatedProductIcon, PlusIcon } from '@/assets/icons';

import { UPDATE_TYPE } from './OrderUpdateProductModal.vue';
import OrderItemCreateForm from './OrderItemCreateForm.vue';

const getDifferencesKeys = (obj) => keys(filter(pipe(isNil, not))(obj));

const TABLE_HEADERS = {
  SKU: 'sku',
  PRODUCT_NAME: 'productName',
  QUANTITY: 'quantity',
  PRICE: 'price',
  DISCOUNT: 'discount',
  ASSOCIATED_ITEMS_TOTAL_AMOUNT: 'associatedItemsTotalAmount',
  TOTAL_AMOUNT: 'totalAmount',
  FIRST_ACTION: 'firstAction',
  SECOND_ACTION: 'secondAction',
};

const decimelPoints = 2;

export default {
  components: {
    AssociatedProductIcon,
    HasAssociatedProductsIcon,
    PlusIcon,
    Tabs,
    Button,
    AdditionalNamesPopOver,
    Table,
    OrderItemCreateForm,
  },
  props: {
    items: { type: Array, default: () => [] },
    orderId: { type: String, required: true },
    supplierProducts: { type: Array, default: () => [] },
    itemsEditable: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const { currencyFormat } = useCurrency();
    const activeTab = ref(0);

    const orderItems = computed(() =>
      props.items.map((orderItem, index) => {
        const associatedItems = props.items
          .map((orderItem, index) => ({ ...orderItem, productIndex: index }))
          .filter((item) => item.associatedTo === orderItem.id)
          .map((item) => ({
            ...item,
            quantity: Math.abs(item.quantity),
          }));
        const associatedItemsTotalAmount = associatedItems.reduce((sum, item) => sum + item.totalAmount, 0);
        return {
          productIndex: index,
          ...orderItem,
          totalAmount: orderItem.totalAmount + associatedItemsTotalAmount,
          associatedItems,
          associatedItemsTotalAmount: associatedItemsTotalAmount ? associatedItemsTotalAmount : null,
          expandable: associatedItems.length,
          expandableCustomClass: 'p-0',
        };
      })
    );

    const orderItemsWithoutAssociatedItems = computed(() =>
      orderItems.value.filter((orderItem) => !orderItem.associatedTo)
    );

    const ordered = computed(() => orderItemsWithoutAssociatedItems.value.filter((p) => p.quantity >= 0));
    const returned = computed(() =>
      orderItemsWithoutAssociatedItems.value
        .filter((p) => p.quantity < 0)
        .map((p) => ({
          ...p,
          quantity: Math.abs(p.quantity),
        }))
    );

    const formatMoney = (value) => formatCurrencyOrDash(value, { ...currencyFormat.value, maximumFractionDigits: 3 });
    const formatPercent = (value) => formatPercentOrDash(value, decimelPoints);

    const formatDecimalPoint = (value) => value.toLocaleString(root.$i18n.locale, { maximumFractionDigits: 3 });

    const activeActions = ref();
    const actionsVisibleChange = (index, isVisible) => (activeActions.value = isVisible ? index : null);

    const handleAction = (action, orderItem) => {
      if (Object.values(UPDATE_TYPE).indexOf(action) !== -1) {
        let value = '-';
        switch (action) {
          case UPDATE_TYPE.DISCOUNT:
            value = formatPercent(orderItem[action]);
            break;
          case UPDATE_TYPE.PRICE:
            value = formatMoney(orderItem[action]);
            break;
          case UPDATE_TYPE.QUANTITY:
            value = orderItem[action].toLocaleString(root.$i18n.locale) ?? value;
            break;
          default:
        }
        emit('update', {
          id: orderItem.id,
          index: orderItem.productIndex,
          product: orderItem.product,
          value,
          updateType: action,
          activeTab: activeTab.value,
        });
      }
    };

    return {
      tableData: computed(() => {
        switch (activeTab.value) {
          case 0:
            return ordered.value;
          case 1:
            return returned.value;
          default:
            return [];
        }
      }),
      tabs: computed(() => [
        {
          text: root.$i18n.t('eventMapModal.orderCard.productTable.tabs.orderedProducts'),
          badgeValue: ordered.value.length,
        },
        {
          text: root.$i18n.t('eventMapModal.orderCard.productTable.tabs.returningProducts'),
          badgeValue: returned.value.length,
        },
      ]),
      formatPercent,
      formatMoney,
      formatDecimalPoint,
      activeTab,
      activeActions,
      actionsVisibleChange,
      handleAction,
      UPDATE_TYPE,
      isNil,
      currentSupplierProducts: computed(() =>
        activeTab.value === 0
          ? props.supplierProducts.filter(({ id }) => ordered.value.every(({ product }) => id !== product.id))
          : props.supplierProducts.filter(({ id }) => returned.value.every(({ product }) => id !== product.id))
      ),
      openCreateForm: ref(false),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('commons.sku'),
          key: TABLE_HEADERS.SKU,
          width: '7.5%',
        },
        {
          header: this.$t('commons.product'),
          key: TABLE_HEADERS.PRODUCT_NAME,
          customClass: 'pe-5 position-relative',
          width: '28.5%',
        },
        {
          header: this.$t('commons.quantity'),
          key: TABLE_HEADERS.QUANTITY,
          width: '6%',
        },
        {
          header: this.$t('commons.price'),
          key: TABLE_HEADERS.PRICE,
          width: '8.8%',
        },
        {
          header: this.$t('commons.discount'),
          key: TABLE_HEADERS.DISCOUNT,
          width: '7.8%',
        },
        {
          header: this.$t('eventMapModal.orderCard.productTable.associatedItems'),
          key: TABLE_HEADERS.ASSOCIATED_ITEMS_TOTAL_AMOUNT,
          width: '10.5%',
        },
        {
          header: this.$t('commons.total'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
          width: '10.5%',
        },
        {
          key: TABLE_HEADERS.FIRST_ACTION,
          customClass: 'p-0 align-middle',
          width: '8rem',
        },
        {
          key: TABLE_HEADERS.SECOND_ACTION,
          width: '3%',
        },
      ];
    },
  },
  methods: {
    hasDifferences(orderItem) {
      return getDifferencesKeys(pick(['price', 'quantity', 'discount'], orderItem.difference ?? {})).length > 0;
    },
    hasDifference(orderItem, type) {
      return orderItem.difference && !isNil(orderItem.difference[type]);
    },
    hasUnsolvedDifference(orderItem, type) {
      return orderItem.difference && !isNil(orderItem.difference[type]) && !orderItem.difference[type].solved;
    },
    hasSolvedDifference(orderItem, type) {
      return orderItem.difference && !isNil(orderItem.difference[type]) && orderItem.difference[type].solved;
    },
    onSolveDiff(orderItemId) {
      this.$emit(
        'solve-diff',
        this.items.findIndex(({ id, differenceItemId }) => id === orderItemId || differenceItemId === orderItemId)
      );
    },
    handleItemCreate() {
      this.$emit('create');
      this.openCreateForm = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../commons/style';
.warning {
  color: $warning;
}

.solved {
  color: $success;
}

.order-items-table {
  tr {
    .difference-button {
      visibility: hidden;
    }

    &:hover .difference-button {
      visibility: visible;
    }
  }
}
</style>
